import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { IDynamicsSettings } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/interfaces';
import { useGeneralSelector } from 'src/hooks';
// import { emptyObject, emptyArray } from 'src/tools';

import {
    CORRECTION_MAP,
    OPERATION_TYPES,
    // PAYMENT_ANALYSIS_TENANT_SELECT,
    PAYMENT_TYPES,
    SALES_METRIC_NAMES,
} from '../../../../constants/constants';
// import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { IPeriod, TMetricResponse } from '../../../../../../General.interfaces';
import { Sales_PosAnalysis_StructureDynamics_Widget_Reducer_Values } from '../reducer';
import { TabListItemIds } from '../../../../enums';
import { DS } from '../../../../../../constants/constants';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { IMetricParams, IRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { IChartDetailing } from '../../../../../../constants/chartDetailing';
import { CORRECTION_RECEIPT_SELECT, VAT_SELECT, DETAIL_SELECT_NAME } from '../constants';

import dataAdapter from './dataAdapter';

// const selectedTab = TabListItemIds.PaymentType;

type FetchStructureDynamicsProps = {
    tenantId: number;
    selectedPosId: string;
    viewSettings?: IDynamicsSettings;
    // selectedTab: TabListItemIds;
};

export const useFetchData = ({ tenantId, selectedPosId, viewSettings }: FetchStructureDynamicsProps) => {
    const { token, allMetrics, currentModuleID, lang, currencyCode } = useGeneralSelector();

    const { selectedTab } = useSelector(Sales_PosAnalysis_StructureDynamics_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    // const universalSelectName = `universalSelect${DS}${currentModuleID}${DS}Structure-Dynamics`;

    // const [{ id: selectedPosId } = emptyObject] = localCurrentOptions?.[universalSelectName] || emptyArray;

    const fetchData = useRequestMetrics();

    const { t } = useTranslation(['metrics', 'translation']);

    const [queryKey, queryFn, adapter] = useMemo(() => {
        const correctionReceipts = localCurrentOptions?.[CORRECTION_RECEIPT_SELECT]?.[0].id as string | undefined;
        const mainPeriod = localCurrentOptions?.mainDateRanges?.find(
            (item) => item.id === localCurrentOptions?.mainPeriod?.id,
        ) as IPeriod | undefined;
        // const comparePeriod = localCurrentOptions?.compareDateRanges?.find(
        //     (item) => item.id === localCurrentOptions?.comparePeriods?.[0]?.id,
        // ) as IPeriod | undefined;

        const vatMode: string = localCurrentOptions?.[VAT_SELECT]?.[0]?.id ?? 'novat';
        const detail: IChartDetailing | undefined = localCurrentOptions?.[DETAIL_SELECT_NAME]
            ? (localCurrentOptions?.[DETAIL_SELECT_NAME] as IChartDetailing)
            : undefined;
        const timeFreq: TTimeFreq = (detail?.id !== undefined ? String(detail?.id) : null) as TTimeFreq;

        // if (mainPeriod?.period && comparePeriod?.period && tenantId && token && correctionReceipts) {
        if (!mainPeriod?.period || !tenantId || !token || !correctionReceipts) {
            return [[]];
        }

        const periods = {
            main: mainPeriod,
            // compare: comparePeriod,
        };

        const generateRequests = (opTypes: string[], metric: string, pType?: string) => {
            const result: IRequestMetricsArgs[] = [];
            Object.entries(periods).forEach(([key, period]) => {
                opTypes.forEach((opType) => {
                    let alias = `${key}${DS}${opType}`;

                    if (pType) {
                        alias += `${DS}${pType}`;
                    }

                    result.push(
                        prepareArgs({
                            timeRange: [mainPeriod?.period?.dateFrom || '', mainPeriod?.period?.dateTo || ''],
                            alias,
                            objIds: [tenantId],
                            metric,
                            timeFreq,
                            token,
                            metricParams: {
                                operation_type: opType,
                                bill_type: CORRECTION_MAP[correctionReceipts],
                                payment_sum: pType,
                                ...(selectedPosId !== 'all' && { point_of_sale_ids: selectedPosId }),
                            },
                        }),
                    );
                });
            });

            return result;
        };

        const requestArgs = SALES_METRIC_NAMES.reduce((acc: IRequestMetricsArgs[], mn) => {
            const metric = `${mn}${vatMode}`;

            switch (selectedTab) {
                case TabListItemIds.PaymentType: {
                    const requests = PAYMENT_TYPES.reduce((acc: IRequestMetricsArgs[], pType: string) => {
                        acc.push(...generateRequests([OPERATION_TYPES[0]], metric, pType));
                        return acc;
                    }, []);

                    return [...acc, ...requests];
                }
                case TabListItemIds.OperationType: {
                    const requests = generateRequests(OPERATION_TYPES, metric);
                    return [...acc, ...requests];
                }

                default:
                    return acc;
            }
        }, []);

        const args: IMetricsDAArgs = { requestArgs, fetchData };

        return [
            [
                'structureDynamics',
                vatMode,
                mainPeriod?.period,
                // comparePeriod?.period,
                selectedTab,
                selectedPosId,
                tenantId,
                timeFreq,
                correctionReceipts,
            ],
            () => metricsDataAccessor(args),
            (response: TMetricResponse[]) =>
                dataAdapter({ response, t, allMetrics, lang, detail, selectedTab, currencyCode, viewSettings }),
        ];
    }, [
        allMetrics,
        fetchData,
        lang,
        localCurrentOptions,
        selectedTab,
        selectedPosId,
        t,
        tenantId,
        token,
        currencyCode,
        viewSettings,
    ]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(adapter),
        select: adapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;

interface IPrepareArgs {
    timeRange: [string, string];
    alias: string;
    metric: string;
    objIds: number[];
    token: string;
    timeFreq: TTimeFreq;
    metricParams?: IMetricParams;
}

const prepareArgs = (args: IPrepareArgs): IRequestMetricsArgs => {
    const { timeRange, alias, metric, objIds, token, metricParams, timeFreq = null } = args;
    return {
        object_aggregation: false,
        time_range: timeRange,
        time_freq: timeFreq,
        obj_ids: objIds,
        metric,
        alias,
        token,
        metric_params: metricParams,
    };
};
