import { ComponentType, ReactNode } from 'react';
import LoadingBox from '../../components/LoadingBox/LoadingBox';

type Base = {};

/**
 * HOC для отображения загрузки или ошибки
 */
export const withLoading = <TProps extends Base>(
    Component: ComponentType<TProps>,
    params: { data: any; height?: number | string },
    hasBorder = true,
) => {
    return (props: TProps & { children?: ReactNode }) => {
        if (params.data?.['status'] === 'Loading') {
            return (
                <LoadingBox
                    height={params.height}
                    hasBorder={hasBorder}
                    text={params.data?.['message'] || ''}
                ></LoadingBox>
            );
        } else if (params.data?.['status'] === 'Error') {
            let parsedMessage = '';
            try {
                parsedMessage = JSON.parse(params.data?.['message']);
            }
            catch (error) {
                console.error(error)
            }
            return (
                <LoadingBox
                    height={params.height}
                    isError
                    hasBorder={hasBorder}
                    text={parsedMessage?.['log'] || ''}
                ></LoadingBox>
            );
        } else if (params.data?.['status'] === 'No data') {
            return (
                <LoadingBox
                    height={params.height}
                    noData
                    hasBorder={hasBorder}
                    text={params.data?.['message'] || ''}
                ></LoadingBox>
            );
        } else {
            return <Component {...props} />;
        }
    };
};
