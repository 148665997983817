import React, { useCallback, useId, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import difference from 'lodash/difference';

import {
    storeSelectedPosForStructureDynamic,
    Sales_PosAnalysis_Module_Reducer_Values,
} from 'src/Chapters/Sales/PosAnalysis/reducer';
import { DS } from 'src/constants/constants';
import { emptyArray, toString } from 'src/tools';
import DynamicsSettings from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/DynamicsSettings';
import { emptyObject } from 'src/tools';
import Stack from 'src/components/Stack/Stack';
import { useGeneralSelector } from 'src/hooks';
import { IChangeOptionsArgs, UniversalSelect } from 'src/components/Selects';
import { Dynamics_Settings_Reducer_Values } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/reducer';
import { IDynamicsSettings } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/interfaces';
import { isMobileInboundWidth } from 'src/theme';
import { TabList } from 'src/components/TabList';

import { posEntitiesByTenantAdapter } from '../api/dataAdapters/posEntitiesByTenantAdapter';
import { useFetchPosEntities } from '../api/useFetchPosEntities';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import Dynamics from '../../../../../components/Charts/Dynamics/Dynamics';
import { withLoading } from '../../../../../tools/API/withLoading';
import DetailSelect from '../../../../../components/Selects/DetailSelect/DetailSelect';
import getChartDataDetailing from '../../../../../constants/chartDetailing';
import { detailOptions } from '../../../../../constants/detailOptions';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import { TTimeFreq } from '../../../../../tools/API/interfaces';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import getResponseStatus from '../../../../../tools/API/helpers/getResponseStatus';
import { TenantPosInfo } from '../api/interfaces';
import { TAB_LIST_ITEMS, CORRECTION_OPTIONS, VAT_OPTIONS } from '../../../constants/constants';
import { TabListItemIds } from '../../../enums';

import { Sales_PosAnalysis_StructureDynamics_Widget_Reducer_Values, storeSelectedTab } from './reducer';
import { useFetchData } from './api/useFetchData';
import {
    ChartWrapper,
    DynamicsSettingsWrapper,
    // DynamicsChartTitle,
    LoaderWrapper,
    // SelectsWrapper,
} from './styles';
import { CORRECTION_RECEIPT_SELECT, VAT_SELECT, DETAIL_SELECT_NAME } from './constants';

export const StructureDynamicsWidget: React.FC = () => {
    const ref = useRef(null);
    const {
        currentModuleID,
        mainAreaSize: { width },
        locations,
    } = useGeneralSelector();

    const structureDynamicId = `${currentModuleID}${DS}StructureDynamics`;

    const { selectedTab } = useSelector(Sales_PosAnalysis_StructureDynamics_Widget_Reducer_Values);

    const { dynamicsSettingsById } = useSelector(Dynamics_Settings_Reducer_Values);
    const viewSettings = dynamicsSettingsById[structureDynamicId] as IDynamicsSettings | undefined;

    const { data: possByTenant = emptyObject } = useFetchPosEntities(posEntitiesByTenantAdapter);
    const { activeTenantIds, selectedPosForStructureDynamic } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);
    const [selectedTenantId] = activeTenantIds || [];

    const selectedPosId = !selectedPosForStructureDynamic ? 'all' : selectedPosForStructureDynamic;

    const { data, error, isFetching, refetch } = useFetchData({
        tenantId: selectedTenantId,
        selectedPosId,
        viewSettings,
    });

    // const [series, setSeries] = useState<TDynamicsSeries[]>([]);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const isNetwork = false;
    // TODO: required to refactoring in separate hook
    const currentLocationId = isNetwork
        ? localCurrentOptions?.['pls']?.[0]?.id
        : localCurrentOptions?.['pl']?.[0]?.id;

    const currentLocation = locations.find(({ id }) => id!.toString() === (currentLocationId ?? '')!.toString());

    const locale: string = currentLocation
        ? `${currentLocation['default_lang']}-${currentLocation['country_code']}`
        : 'ru-RU';
    // const locale = 'ae-SA';

    const dynamicChartId = useId();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const tabListSelectHandler = (id: TabListItemIds) => {
        dispatch(storeSelectedTab(id));
    };

    const isMobile = isMobileInboundWidth(width);

    const WithLoading = useMemo(() => {
        const Component = withLoading(LoaderWrapper, {
            data: getResponseStatus({
                isDataExists: data ? data?.isDataExists : false,
                isFetching: isFetching,
                error: error,
                data: data,
            }),
            height: 400,
        });

        return Component;
    }, [data, error, isFetching]);

    const restrictedOptions = useMemo(() => {
        const validDetailing = localCurrentOptions?.mainDateRanges
            ? getChartDataDetailing(t, localCurrentOptions?.mainDateRanges, localCurrentOptions?.mainPeriod)
            : emptyArray;

        return [
            ...difference(
                detailOptions.map((item) => item.id),
                validDetailing.map((item) => item.id),
            ),
            '15MIN',
        ] as TTimeFreq[];
    }, [localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges, t]);

    const posSelectOptions = useMemo(
        () =>
            ((possByTenant?.[selectedTenantId] || []) as TenantPosInfo[]).reduce(
                (acc, pos) => [
                    ...acc,
                    {
                        id: pos?.id,
                        text: toString(pos?.registrationId || pos?.factoryId),
                    },
                ],
                [{ id: 'all', text: t('All') }],
            ),
        [selectedTenantId, possByTenant, t],
    );

    const onPosSelect = useCallback((args: IChangeOptionsArgs) => {
        const selectedOption = args.newOptions?.['selectedPosOptions'] as Array<{ id: string }>;
        const [selectedId] = selectedOption;
        dispatch(storeSelectedPosForStructureDynamic(toString(selectedId.id)));
    }, [dispatch]);

    const tabList = useMemo(() => {
        return (
            <TabList
                selectedValue={selectedTab}
                selectHandler={tabListSelectHandler}
                widgetName={'StructureAndDynamics'}
                options={TAB_LIST_ITEMS}
                addComponent={
                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                        <DynamicsSettings
                            eventsProps={{ visible: false }}
                            weatherProps={{ visible: false }}
                            trendSeriesProps={{ visible: false }}
                            averageSeriesProps={{ visible: false }}
                            id={structureDynamicId}
                        />
                    </div>
                }
            />
        );
    }, [selectedTab, t]);

    // const giveSeriesOutside = (series: TDynamicsSeries[]) => {
    //     setSeries(series);
    // };

    if (activeTenantIds?.length !== 1) {
        return null;
    }

    const selectedPosOptions = posSelectOptions.filter(({ id }) => id === selectedPosId);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Structure and dynamics')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName={'Structure and dynamics'}
                        reloadHandlerNoDispatch={refetch}
                    />
                </WidgetTitle>
                {!isMobile && tabList}

            </WidgetTitleWrapper>
            {isMobile && tabList}

            <Stack flexWrap="wrap" justifyContent="space-between" gap={15}>
                <div />
                <Stack flexWrap={'wrap'} gap={15}>
                    <DetailSelect
                        name={DETAIL_SELECT_NAME}
                        restrictedOptions={restrictedOptions}
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                    />

                    {(posSelectOptions.length > 2) &&
                        <UniversalSelect
                            name="selectedPosOptions"
                            options={posSelectOptions}
                            changeOptions={onPosSelect}
                            localCurrentOptions={{ selectedPosOptions }}
                            labelText={t('POSes')}
                            maxSelected={1}
                            mode="single"
                        />
                    }

                    <UniversalSelect
                        name={VAT_SELECT}
                        options={VAT_OPTIONS}
                        text={t('Use VAT')}
                        labelText={t('Use VAT')}
                        dropdownWidth="sm"
                        mode="single"
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                    />
                    <UniversalSelect
                        name={CORRECTION_RECEIPT_SELECT}
                        options={CORRECTION_OPTIONS}
                        defaultSelected={[CORRECTION_OPTIONS[0]]}
                        text={t('Correction receipts')}
                        labelText={t('Correction receipts')}
                        dropdownWidth="auto"
                        mode="single"
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                    />
                </Stack>
            </Stack>

            <WithLoading>
                <ChartWrapper>
                    {data?.structureChartOptions ? (
                        <Dynamics
                            chartId={dynamicChartId}
                            {...data.structureChartOptions}
                            externalChartSettings={{
                                highlightWeekends: dynamicsSettingsById[structureDynamicId]?.weekends.isActive,
                                selectedSeriesIdsForTrend: emptyArray,
                                selectedSeriesIdsForAverage: emptyArray,
                            }}
                            locale={locale}
                        />
                    ) : null}
                </ChartWrapper>
                {/* <Divider style={{ marginBottom: 'var(--space-xs)' }} /> */}
                {/* <DynamicsChartTitle>{t('Deviation in % to the comparison period')}</DynamicsChartTitle> */}
                {/* <ChartWrapper>
                    {data?.dynamicsChartOptions ? (
                        <Dynamics
                            chartId={dynamicChartId}
                            {...data.dynamicsChartOptions}
                        />
                    ) : null}
                </ChartWrapper> */}
            </WithLoading>
        </WidgetWrapper>
    );
};
