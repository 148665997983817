import { useMemo, useCallback, useRef, useId } from 'react';

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
    PositioningImperativeRef,
    PositioningVirtualElement,
    Menu,
    MenuGroup,
    MenuPopover,
    MenuTrigger,
    Divider,
    MenuList,
} from '@fluentui/react-components';

import {
    ContextualMenu,
    StyledMenuGroupHeader,
    StyledMenuItem,
    // MenuConfig,
    getContextMenuRect,
    getContextualMenuBody,
} from 'src/components/ContextualMenu';
import { toString } from 'src/tools';
import { FilterInput } from 'src/components/FilterInput';

import { useDebounce } from '../../../../../../../../../hooks/useDebounce';
import { EventsManagementContext, IContext } from '../../../../context';
import { TFiltersMode } from '../../../../interfaces';
import getCellStringValue from '../../../../tools/getCellStringValue';

/**
 * Компонент для отображения контекстного окна с фильтрами
 */
const FilterContextualMenu = () => {
    const context = useContext(EventsManagementContext);

    const menuColumnKey = toString(context?.contextMenuTarget?.cell?.key);

    const filter = { key: menuColumnKey, ...context?.filtersByCellKey[menuColumnKey]! };

    const debouncedFilter = useDebounce(filter);
    const { t } = useTranslation();

    useEffect(() => {
        if (debouncedFilter && debouncedFilter.filter !== undefined) {
            context?.addNewFilterTrans(debouncedFilter);
        }
    }, [debouncedFilter]);

    const changeValue = (value: string, mode?: TFiltersMode) => {
        if (context && context.contextMenuTarget) {
            const key = context.contextMenuTarget.cell.key;

            context?.addNewFilterTrans({ filter: value, key, mode });
            closeContextualMenu();
        }
    };

    // const renderFilterItem = () => {
    //     if (!context?.contextMenuTarget) return null;
    //     return <FilterInput value={filter?.filter || ''} changeValue={changeValue} isOutsideControlled />;
    // };
    // const onContextMenu = useCallback(
    //     (event: MouseEvent) => {
    //         event.preventDefault();
    //         setContextMenuEvent({ ...event });
    //     },
    //     [setContextMenuEvent],
    // );

    // const onMouseDown = useCallback((event: MouseEvent) => {
    //     // to prevent display browser's menu when context menu is opened
    //     tableContextRef.current.closeMenu(event);
    // }, []);

    const closeContextualMenu = () => {
        context?.storeContextMenuTragetTrans(null);
    };

    //  (e: MouseEvent, cell: ITableCellData) => {
    //     e.preventDefault();
    //     const cursorCoords = { x: e.clientX, y: e.clientY };
    //     context?.storeContextMenuTragetTrans({ cursorCoords, cell

    const resetFilters = () => {
        context?.resetFiltersTrans();
        context?.storeContextMenuTragetTrans(null);
    };

    const handleChangeInput = (value: string, mode?: TFiltersMode) => {
        if (context?.contextMenuTarget) {
            context?.addNewFilterTrans?.({ filter: value, key: menuColumnKey, mode });
        }
    }

    const inputSubMenu = (
        <Menu persistOnItemClick hoverDelay={10} key={`filter`}>
            <MenuTrigger disableButtonEnhancement>
                <StyledMenuItem area-disabled key={`filter`}>
                    {t('Filter')}
                </StyledMenuItem>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {context?.contextMenuTarget && (
                        <FilterInput
                            value={filter?.filter || ''}
                            changeValue={handleChangeInput}
                            onCloseFilter={closeContextualMenu}
                            isOutsideControlled
                        />
                    )}
                </MenuList>
            </MenuPopover>
        </Menu>
    );

    const handleFilterBuValue = () => {
        let value = '';
        if (context && context?.contextMenuTarget) {
            value = getCellStringValue(
                context.contextMenuTarget.cell,
                context.reportingObjectsById,
                context.eventTypesById,
            );
        }
        changeValue(value);
        context?.storeContextMenuTragetTrans(null);
    }

    const filtersMenu = (
        <MenuGroup>
            <StyledMenuGroupHeader>
                {t('Filters')}
            </StyledMenuGroupHeader>
            <Divider />
            <MenuList>
                {inputSubMenu}
                <StyledMenuItem key="filterByValue" onClick={handleFilterBuValue}>
                    {t('Filter by value')}
                </StyledMenuItem>
                <StyledMenuItem key="filterByAnyValue" onClick={() => changeValue('__', 'Any value')}>
                    {t('Filter by any value')}
                </StyledMenuItem>
                <StyledMenuItem key="filterByNoValue" onClick={() => changeValue('__', 'No value')}>
                    {t('Filter by no value')}
                </StyledMenuItem>
                <StyledMenuItem key="reset" onClick={resetFilters}>
                    {t('Reset filters')}
                </StyledMenuItem>
            </MenuList>
        </MenuGroup>
    );

    const contextMenuTargetRef = useRef<PositioningImperativeRef>(null);

    const cursorCoords = context?.contextMenuTarget?.cursorCoords;

    const isOpened = useMemo(() => {
        const { x, y } = cursorCoords || {};
        const virtualElement: PositioningVirtualElement = {
            getBoundingClientRect: getContextMenuRect(x, y),
        };
        contextMenuTargetRef.current?.setTarget(virtualElement);

        return !!cursorCoords;
    }, [cursorCoords]);

    return (
        <ContextualMenu
            key="events-filters-menu"
            isContextualMenuOpen={isOpened}
            closeContextualMenu={closeContextualMenu}
            contextualMenuBody={filtersMenu!}
            positioningRef={contextMenuTargetRef}
        />
    );
};

export default FilterContextualMenu;
