import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { IPeriod } from '../../../General.interfaces';
import { generalReducerValues } from '../../../General.reducer';
import { stringDate } from '../../../tools/Strings/stringDate';
import { userSettingsReducerValues } from '../../UserSettings/reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import Select from '../Select/Select';
import { IObject } from '../Select/interfaces';
import Modals from '../../Modals/Modals';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import { useAppDispatch } from '../../../hooks/useSettings';
import { appendRest, getRest } from '../../../hooks/useCurrentOptions';
import { shortStringDate } from '../../../tools/Strings/shortStringDate';

const MainPeriodSelect: React.FC<IProps> = ({
    validOptions = [],
    name = 'mainPeriod',
    label = 'Main period',
    oneDayOnly = false,
    defaultPeriod,
    disabled = false,
    yearsOnly = false,
    localCurrentOptions,
    changeOptions,
    ...props
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [modalStatus, setModalStatus] = useState({ show: false });

    const { locations, lang, currentModuleID, selectedLocationId } = useSelector(generalReducerValues);
    const mainPeriod = localCurrentOptions?.[name];
    const localCurrentModule = localCurrentOptions?.['currentModuleID'];
    const { currentOptions } = useSelector(userSettingsReducerValues);

    // useEffect(() => {
    //     if (validOptions.length && !validOptions.includes(mainPeriod?.id || '')) {

    //         handleSelect([{ id: validOptions[0] }]);
    //     }
    // }, [currentModuleID]);

    useEffect(() => {
        if (!mainPeriod && selectedLocationId && localCurrentModule === currentModuleID) {
            if (!defaultPeriod) {
                changeOptions({
                    newOptions: {
                        [name]: { id: 'previousMonth' },
                    },
                });
            } else {
                changeOptions({
                    newOptions: {
                        [name]: { id: defaultPeriod },
                    },
                });
            }
        }
    }, [currentModuleID, defaultPeriod, dispatch, localCurrentModule, mainPeriod, selectedLocationId]);

    const mainDateRanges = useMemo(() => {
        const rest = getRest(name);
        const rangesKey = appendRest('mainDateRanges', rest);
        return currentOptions[rangesKey];
    }, [JSON.stringify(currentOptions), name]);

    const getOutsideText = (period: IPeriod) => {
        if (!period) return '';
        const dates = mainDateRanges?.find((item: { id: string }) => item.id === period.id)?.period;
        return shortStringDate({ dateFrom: dates?.dateFrom, dateTo: dates?.dateTo }, lang);
        // if (period.id === 'today' || period.id === 'yesterday') {
        //     // return stringDate(dates?.dateFrom, lang);

        // } else {
        //     return `${stringDate(dates?.dateFrom, lang)} – ${stringDate(dates?.dateTo, lang)}`;
        // }
    };

    const options =
        validOptions.length === 0
            ? mainDateRanges
            : mainDateRanges?.filter((range: { id: string }) => validOptions.includes(range.id));

    const handleDateRangeChange = (period: { dateFrom: string; dateTo: string }) => {
        props.handleSelect && props.handleSelect({ id: 'selectCustom', period });
        selectedLocationId &&
            changeOptions({
                newOptions: {
                    [name]: { id: 'selectCustom', period },
                },
            });
        setModalStatus({ show: false });
    };

    const handleSelect = (args: { id: string | number | null }[]) => {
        if (!args?.[0] || args[0].id === null) return;
        if (args[0].id !== 'selectCustom') {
            props.handleSelect && props.handleSelect({ id: String(args[0].id) });
            selectedLocationId &&
                changeOptions({
                    newOptions: {
                        [name]: { id: String(args[0].id) },
                    },
                });
        } else setModalStatus({ show: true });
    };

    const closeModal = () => {
        setModalStatus({ show: false });
    };

    return mainPeriod ? (
        <Wrapper>
            <Modals title="" modalStatus={modalStatus} closeModal={closeModal}>
                <DateRangePicker
                    handleDateRangeChange={handleDateRangeChange}
                    oneDayOnly={oneDayOnly}
                    yearsOnly={yearsOnly}
                />
            </Modals>
            <Select
                testId={`${currentModuleID}_mainPeriod`}
                disabled={locations.length === 0 || disabled}
                options={options as IObject[]}
                dropdownWidth="sm"
                dropdownAlign="left"
                showFilter={false}
                mode="single"
                labelText={t(label)}
                iconType="chevron-down"
                handleSelect={handleSelect}
                outsideText={getOutsideText(mainPeriod)}
                outsideSelected={[mainPeriod]}
                bgColor="primary-lighter"
            />
        </Wrapper>
    ) : null;
};

export default MainPeriodSelect;
