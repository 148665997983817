import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Settings24Regular } from '@fluentui/react-icons';

import { returnValueOrDefault } from 'src/tools';
import { TDynamicsSeries } from 'src/components/CommonWidgets/DynamicsPMO/components/Dynamics/interfaces';

import ContextualMenu from '../../../../ContextualMenu/ContextualMenu';
import Tooltip from '../../../../Tooltip/Tooltip';
import IconButton from '../../../../IconButton/IconButton';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import Stack from '../../../../Stack/Stack';
import Checkbox from '../../../../Checkbox/Checkbox';
import Select from '../../../../Selects/Select/Select';
import { AVERAGE_SERIES_ID_PART, TREND_SERIES_ID_PART } from '../../constants/constants';

import {
    Dynamics_Settings_Reducer_Values,
    fillInitialDynamicsSettingsById,
    updateDynamicsSettingsById,
} from './reducer';
import { IDynamicsSettings, IProps } from './interfaces';

export const checkVisibility = (isVisible?: boolean) => returnValueOrDefault(isVisible, true);

const DynamicsSettings = <T extends TDynamicsSeries = TDynamicsSeries>({
    weekendsActiveByDefault,
    weatherActiveByDefault,
    eventsActiveByDefault,
    ...props
}: IProps<T>): JSX.Element => {
    const { dynamicsSettingsById } = useSelector(Dynamics_Settings_Reducer_Values);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currentSettings = dynamicsSettingsById[props.id] as IDynamicsSettings | undefined;

    const seriesOptions = (currentSettings?.series || [])
        .filter(
            (item) =>
                item.id && !(item.id?.includes(TREND_SERIES_ID_PART) || item.id?.includes(AVERAGE_SERIES_ID_PART)),
        )
        .map((item) => ({ id: item.id || '', text: item.name || '' }));

    useEffect(() => {
        if (!dynamicsSettingsById[props.id]) {
            dispatch(fillInitialDynamicsSettingsById({
                id: props.id,
                series: props.series,
                weekendsActiveByDefault,
                weatherActiveByDefault,
                eventsActiveByDefault,
            }));
        } else {
            dispatch(
                updateDynamicsSettingsById({
                    id: props.id,
                    data: { key: 'series', value: props.series || [], shouldRewrite: true },
                }),
            );
        }
    }, [dispatch, props.id, props.series]);

    const toggleContextualMenuOpen = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const updateDynamicsSettings = (key: string, value: object) => {
        dispatch(updateDynamicsSettingsById({ id: props.id, data: { key, value } }));
    };

    return (
        <ContextualMenu
            contextualMenuBody={
                <Stack styles={{ padding: '10px' }} direction="column" gap={10}>
                    {checkVisibility(props.weatherProps?.visible) && (
                        <Checkbox
                            handleToggle={(data) => updateDynamicsSettings('weekends', { isActive: data.checked })}
                            selected={currentSettings?.weekends.isActive}
                            disabled={props.weekendsProps?.disabled}
                            label={t('Highlight weekend')}
                        />
                    )}

                    {checkVisibility(props.eventsProps?.visible) && (
                        <Checkbox
                            handleToggle={(data) => updateDynamicsSettings('events', { isActive: data.checked })}
                            selected={currentSettings?.events.isActive}
                            disabled={props.eventsProps?.disabled}
                            label={t('Highlight events')}
                        />
                    )}

                    {checkVisibility(props.weatherProps?.visible) && (
                        <Checkbox
                            handleToggle={(data) => updateDynamicsSettings('weather', { isActive: data.checked })}
                            selected={currentSettings?.weather.isActive}
                            disabled={props.weatherProps?.disabled}
                            label={t('Show weather')}
                        />
                    )}

                    {checkVisibility(props.trendSeriesProps?.visible) && (
                        <Select
                            testId={`${props.id}_selectedSeriesIdsForTrend`}
                            handleSelect={(data) =>
                                updateDynamicsSettings('trendSeries', {
                                    selectedSeriesIds: data.map((item) => item.id),
                                })
                            }
                            outsideSelected={seriesOptions.filter((item) =>
                                currentSettings?.trendSeries.selectedSeriesIds.includes(item.id),
                            )}
                            options={seriesOptions}
                            labelText={t('Trend for')}
                            text={t('Selected')}
                            showFilter={false}
                            dropdownWidth="md"
                            mode="multi"
                        />
                    )}

                    {checkVisibility(props.averageSeriesProps?.visible) && (
                        <Select
                            testId={`${props.id}_selectedSeriesIdsForAverage`}
                            handleSelect={(data) =>
                                updateDynamicsSettings('averageSeries', {
                                    selectedSeriesIds: data.map((item) => item.id),
                                })
                            }
                            options={seriesOptions}
                            outsideSelected={seriesOptions.filter((item) =>
                                currentSettings?.averageSeries.selectedSeriesIds.includes(item.id),
                            )}
                            labelText={t('Average for')}
                            text={t('Selected')}
                            showFilter={false}
                            dropdownWidth="md"
                            mode="multi"
                        />
                    )}
                </Stack>
            }
            isContextualMenuOpen={isContextualMenuOpen}
            closeContextualMenu={closeContextualMenu}
            gapSpace={10}
        >
            <div>
                <Tooltip content={t('Diagram settings')}>
                    <IconButton
                        onClick={toggleContextualMenuOpen}
                        icon={<Settings24Regular />}
                        testId={props.id}
                        size={24}
                    />
                </Tooltip>
            </div>
        </ContextualMenu>
    );
};

export default DynamicsSettings;
