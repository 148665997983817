import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';

import Stack from '../../Stack/Stack';
import { generalReducerValues } from '../../../General.reducer';
import { shortStringDate } from '../../../tools/Strings/shortStringDate';
import useTranslation from '../../../hooks/useTranslation/useTranslation';
import { withLoading } from '../../../tools/API/withLoading';
import getResponseStatus from '../../../tools/API/helpers/getResponseStatus';

import { TITLE_MAP_BY_CHART_TYPE } from './constants/constants';
import Selects from './components/Selects/Selects';
import { BeforeWrapper, ChartWrapper, LoadingWrapper, Title } from './styles';
import useFetchData from './api/useFetchData';
import { DynamicsPMOProps } from './interfaces';
import Dynamics from './components/Dynamics/Dynamics';
import { TDynamicsSeries } from './components/Dynamics/interfaces';

/**
 * Виджет для отображения динамик по периодам, метрикам и объектам
 */
const DynamicsPMO: FC<DynamicsPMOProps> = ({ beforeSelects, ...props }) => {
    const { lang } = useSelector(generalReducerValues);
    const { data, isFetching, error } = useFetchData(props);
    const { t } = useTranslation();

    /** Выдача серий наружу */
    useEffect(() => {
        if (data?.chartOptions.options?.series?.length && props.giveSeriesOutside) {
            props.giveSeriesOutside((data?.chartOptions.options?.series || []) as TDynamicsSeries[]);
        }
    }, [data?.chartOptions.options?.series, props]);

    const WithLoading = useMemo(() => {
        const Component = withLoading(
            LoadingWrapper,
            {
                data: getResponseStatus({
                    isFetching,
                    error,
                    data,
                }),
                height: props.viewSettings?.loadingBoxHeight ?? 400,
            },
            props.viewSettings?.showLoadingBorder ?? true,
        );

        return Component;
    }, [error, JSON.stringify(data), isFetching]);

    const { isShowTitle = true } = props.viewSettings || {};

    return (
        <div>
            <Stack flexWrap="wrap" justifyContent="space-between" gap={15}>
                <Title>
                    {isShowTitle && <div>{t(TITLE_MAP_BY_CHART_TYPE[props.chartType] || '') || props.chartType}</div>}
                    {isShowTitle && props.chartType !== 'periods' && (
                        <div>
                            {shortStringDate(
                                { dateFrom: props.mainPeriod.dateFrom, dateTo: props.mainPeriod.dateTo },
                                lang,
                            )}
                        </div>
                    )}
                </Title>

                <Stack flexWrap="wrap" justifyContent="space-between" gap={15}>
                    <BeforeWrapper>
                        {beforeSelects}
                    </BeforeWrapper>
                    <Selects {...omit(props, ['comparePeriods'])} />
                </Stack>
            </Stack>
            <WithLoading>
                {data && (
                    <ChartWrapper style={props.viewSettings?.chartWrapperStyles}>
                        <Dynamics {...data.chartOptions} />
                    </ChartWrapper>
                )}
            </WithLoading>
        </div>
    );
};

export default DynamicsPMO;
