import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';
import { generalReducerValues } from '../../../../../../General.reducer';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { mlFromLocation } from '../../../../../../tools/mlFromLocation';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { usePrepareData } from './usePrepareData';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import {
    Maps_MapsOverview_Module_Reducer_Values,
    storePlans,
    storeVersions,
    storeLayers,
    storeZonesByGroup,
    storeSelectedGroup,
    storeSelectedZoneId,
    storeSelectedPlaceId,
    storeInitialData,
    storeSelectedVersionId,
} from '../../../reducer';
import { DS, ZONES_WORD } from '../../../../../../constants/constants';
import requestPlans from '../../../../../../tools/API/requestPlans';
import requestVersions from '../../../../../../tools/API/requestVersions';
import requestLayers from '../../../../../../tools/API/requestLayers';
import { IRequestMetricsArgs } from '../../../../../../tools/API/interfaces';

export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();

    usePrepareData();
    const [initialLayers, setInitialLayers] = useState<any>([]);
    const {
        token,
        locations,
        selectedLocationId,
        structures,
        cfg: { reportingObjectsByType, reportingObjectsByTypeAndMarker },
        urlsByServices,
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const { selectedObjectType, selectedGroupId, selectedVersionId, versions, moduleName } = useSelector(
        Maps_MapsOverview_Module_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const dispatch = useDispatch();

    const [plansUrl, layersUrl, versionsUrl] = useMemo(() => {
        if (urlsByServices?.['core/map-service-back']) {
            return [
                urlsByServices['core/map-service-back'].PLANS_URL,
                urlsByServices['core/map-service-back'].LAYERS_URL,
                urlsByServices['core/map-service-back'].VERSIONS_URL,
            ];
        }
        return [];
    }, [urlsByServices]);

    useEffect(() => {
        // Тут мы анализируем изменения основного периода и , если надо меняем текущую версию
        const mainPeriod = localCurrentOptions?.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
        const period = mainDateRange?.period;
        const filtered = versions?.filter((version) => {
            return (
                period?.dateTo &&
                version.date_from &&
                DateTime.fromISO(period.dateTo).toMillis() >= DateTime.fromISO(version.date_from).toMillis() &&
                (!version.date_to ||
                    (version.date_to &&
                        DateTime.fromISO(period.dateTo).toMillis() <= DateTime.fromISO(version.date_to).toMillis()))
            );
        })[0];

        if (filtered?.id && filtered?.id !== selectedVersionId) {
            dispatch(storeSelectedVersionId(filtered.id));
        }
    }, [localCurrentOptions?.mainPeriod?.id]);

    useEffect(() => {
        if (!Object.keys(reportingObjectsByType).length) return;
        const allZones = Object.entries(reportingObjectsByTypeAndMarker)
            .filter((entry) => entry[0].includes(ZONES_WORD))
            .map((entry) => entry[1]);
        const allPlaces = reportingObjectsByType['place'];

        if (!allZones) {
            dispatch(storeZonesByGroup({}));
            dispatch(storeSelectedGroup(null));
            dispatch(storeSelectedZoneId(null));
            return;
        }

        if (!allPlaces) {
            dispatch(storeSelectedPlaceId(null));
            return;
        }

        const zonesByGroup = {};
        allZones.forEach((zone) => {
            const groupMarker = zone.object_params.group_marker;
            if (groupMarker && zonesByGroup[groupMarker]) {
                zonesByGroup[groupMarker] = [...zonesByGroup[groupMarker], zone];
            } else if (groupMarker && !zonesByGroup[groupMarker]) {
                zonesByGroup[groupMarker] = [zone];
            }
        });
        dispatch(storeZonesByGroup(zonesByGroup));
        const selectedGroupId = Object.keys(zonesByGroup).map((groupMarker) => groupMarker)[0];
        dispatch(storeSelectedGroup(selectedGroupId));
        const selectedZoneId = zonesByGroup[selectedGroupId]?.[0]?.id;
        dispatch(storeSelectedZoneId(selectedZoneId));
        const selectedPlaceId = allPlaces?.[0].id;
        dispatch(storeSelectedPlaceId(selectedPlaceId));
    }, [reportingObjectsByType]);

    useEffect(() => {
        if (!selectedLocationId || localCurrentOptions?.currentModuleID !== 'Maps:Maps overview') return;

        const location = locations?.find((item) => item.id === selectedLocationId);
        const mlId = mlFromLocation(location);

        if (mlId && token && plansUrl && versionsUrl) {
            dispatch(storePlans({ status: 'Loading' }));
            dispatch(storeVersions([]));

            requestPlans({ mlId, token, url: `${plansUrl}?location_id=${mlId}` }).then((response) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storePlans,
                    error: storePlans,
                    dispatch,
                    res: response,
                });
            });
            requestVersions({ mlId, token, url: versionsUrl }).then((response) => {
                !response.message && dispatch(storeVersions(response.data));
            });
        }
    }, [selectedLocationId, localCurrentOptions?.currentModuleID, locations, plansUrl, versionsUrl]);

    useEffect(() => {
        if (!selectedVersionId) return;

        const location = locations?.find((item) => item.id === selectedLocationId);
        const mlId = mlFromLocation(location);

        if (mlId && token && layersUrl) {
            dispatch(storeLayers([]));
            requestLayers({
                mlId,
                token,
                versionId: String(selectedVersionId),
                url: `${layersUrl}?location_id=${mlId}&version_id=${selectedVersionId}`,
            }).then((response) => {
                !response.message && setInitialLayers(() => response.data);
            });
        }
    }, [selectedLocationId, selectedVersionId, token, layersUrl]);

    useEffect(() => {
        if (initialLayers.length && structures?.relations_tenant2place) {
            const mainPeriod = localCurrentOptions?.mainPeriod?.id;
            const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
            const period = mainDateRange?.period;

            const filteredTenant2Place = filterValidDateRanges(structures?.['relations_tenant2place'], period);

            const newLayers = cloneDeep(initialLayers);

            initialLayers.forEach((layer: any) => {
                if (layer.layer_type === 'places_layer') {
                    const data = layer.data?.map((item: any) => {
                        const marker = filteredTenant2Place?.find(
                            (t2p) => t2p.place_marker === item.marker,
                        )?.tenant_marker;
                        return { ...item, marker };
                    });
                    const newLay = { ...layer, layer_type: 'tenants_layer', data };
                    newLayers.push(newLay);
                }
            });

            dispatch(storeLayers(newLayers));
        } else if (initialLayers.length && !structures?.relations_tenant2place) {
            dispatch(storeLayers(initialLayers));
        }
    }, [initialLayers, structures?.['relations_tenant2place']]);

    const showComparison: Boolean = localCurrentOptions?.['mapsSettings']
        .map((item: { id: string }) => item.id)
        ?.includes('comparison');

    useEffect(() => {
        dispatch(storeInitialData([]));
        if (!localCurrentOptions || !localCurrentOptions?.mainDateRanges || !localCurrentOptions?.comparePeriods)
            return;
        const controller = new AbortController();
        const signal = controller.signal;

        let obj_ids = reportingObjectsByType?.[selectedObjectType]?.map((item) => item.id);
        if (selectedObjectType.includes(ZONES_WORD)) {
            obj_ids = reportingObjectsByType[selectedObjectType]
                ?.filter((item) => item.object_params.group_marker === selectedGroupId)
                .map((item) => item.id);
        }

        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const [mainDateRange] = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod);
        const mainTimeRange = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];
        const comparePeriodId = localCurrentOptions?.comparePeriods?.[0]?.id;
        const [compareDateRange] = localCurrentOptions.compareDateRanges?.filter((item) => item.id === comparePeriodId);
        const compareTimeRange = [compareDateRange?.period.dateFrom, compareDateRange?.period.dateTo];

        const requests: IRequestMetricsArgs[] = [];
        localCurrentOptions?.selectedMetrics?.forEach((metric) => {
            const request: IRequestMetricsArgs = {
                signal,
                token,
                alias: `${metric}${DS}main`,
                metric,
                obj_ids,
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };
            requests.push(request);
            if (showComparison) {
                requests.push({ ...request, alias: `${metric}${DS}compare`, time_range: compareTimeRange });
            }
        });
        dispatch(storeInitialData({ status: 'Loading', message: t('Loading...') }));

        requests &&
            fetchData(requests).then((res: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeInitialData,
                    error: storeInitialData,
                    dispatch,
                    res,
                });
            });

        return () => {
            controller.abort();
        };
    }, [
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.mainPeriod,
        selectedLocationId,
        localCurrentOptions?.comparePeriods,
        selectedObjectType,
        selectedGroupId,
        showComparison,
    ]);
};
